@font-face {
  font-family: 'Apercu Pro';
  src: local('Apercu'), local('Apercu Pro'),
    url('fonts/ApercuPro/Apercu-Regular-Pro.ttf') format('truetype'),
    url('fonts/ApercuPro/Apercu-Regular-Pro.woff') format('woff'),
    url('fonts/ApercuPro/Apercu-Regular-Pro.woff2') format('woff2');
  font-display: block;
}

@font-face {
  font-family: 'Apercu Pro';
  src: local('Apercu-bold'),
    url('fonts/ApercuPro/Apercu-Bold-Pro.ttf') format('truetype'),
    url('fonts/ApercuPro/Apercu-Bold-Pro.woff') format('woff'),
    url('fonts/ApercuPro/Apercu-Bold-Pro.woff2') format('woff2');
  font-display: block;
  font-weight: 700;
}

html,
body {
  font-family: 'Apercu Pro', sans-serif;
  line-height: 16px;
}

body {
  background-color: white;
  margin: 8px;
}
